.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.w-240px {
	width: 240px !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.min-w-220px {
	min-width: 220px !important;
}

.max-w-24px {
	max-width: 24px !important;
}

.w-85 {
	width: 85% !important;
}

.w-160px {
	width: 160px !important;
}

.w-52px {
	width: 52px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-700px {
	max-width: 700px !important;
}

.max-w-450px {
	max-width: 450px !important;
}

.w-500px {
	width: 500px !important;
}

.w-75 {
	width: 75% !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.max-w-135px {
	max-width: 135px !important;
}

.w-120px {
	width: 120px !important;
}

.max-w-800px {
	max-width: 800px !important;
}

.w-50 {
	width: 50% !important;
}

