.cursor-pointer {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.border-top {
  border-top: 1px solid var(--bs-gray-300);
}


.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-10 {
  z-index: 10;
}


.border-radius-50px {
	border-radius: 50px !important;
}

.border-radius-500px {
	border-radius: 500px !important;
}

.border-radius-400px {
	border-radius: 400px !important;
}

