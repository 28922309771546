.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}

@font-face {
	font-family: italic;
	src: url('../../assets/fonts/DMSerifDisplay-Italic.ttf');
}

.ff-italic {
font-family: italic;
}

@font-face {
	font-family: regular;
	src: url('../../assets/fonts/DMSerifDisplay-Regular.ttf');
}

.ff-regular {
font-family: regular;
}

@font-face {
	font-family: textBold;
	src: url('../../assets/fonts/Raleway-Bold.ttf');
}

.ff-textBold {
font-family: textBold;
}

@font-face {
	font-family: textExtrabold;
	src: url('../../assets/fonts/Raleway-ExtraBold.ttf');
}

.ff-textExtrabold {
font-family: textExtrabold;
}

@font-face {
	font-family: textSemibold;
	src: url('../../assets/fonts/Raleway-SemiBold.ttf');
}

.ff-textSemibold {
font-family: textSemibold;
}

@font-face {
	font-family: textMedium;
	src: url('../../assets/fonts/Raleway-Medium.ttf');
}

.ff-textMedium {
font-family: textMedium;
}

@font-face {
	font-family: dmserifdisplayitalic;
	src: url('../../assets/fonts/DMSerifDisplay-Italic.ttf');
}

.ff-dmserifdisplayitalic {
font-family: dmserifdisplayitalic;
}

@font-face {
	font-family: dmserifdisplayregular;
	src: url('../../assets/fonts/DMSerifDisplay-Regular.ttf');
}

.ff-dmserifdisplayregular {
font-family: dmserifdisplayregular;
}

@font-face {
	font-family: ralewaymedium;
	src: url('../../assets/fonts/Raleway-Medium.ttf');
}

.ff-ralewaymedium {
font-family: ralewaymedium;
}

@font-face {
	font-family: ralewaybold;
	src: url('../../assets/fonts/Raleway-Bold.ttf');
}

.ff-ralewaybold {
font-family: ralewaybold;
}

@font-face {
	font-family: ralewayextrabold;
	src: url('../../assets/fonts/Raleway-ExtraBold.ttf');
}

.ff-ralewayextrabold {
font-family: ralewayextrabold;
}

@font-face {
	font-family: ralewaysemibold;
	src: url('../../assets/fonts/Raleway-SemiBold.ttf');
}

.ff-ralewaysemibold {
font-family: ralewaysemibold;
}



// font
