.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.px-16px {
	padding-left: 16px;
	padding-right: 16px;
}

.py-48px {
	padding-top: 48px;
	padding-bottom: 48px;
}

.pe-24px {
	padding-right: 24px;
}

.py-2px {
	padding-top: 2px;
	padding-bottom: 2px;
}

.px-6px {
	padding-left: 6px;
	padding-right: 6px;
}

.pb-3px {
	padding-bottom: 3px;
}

.pe-8px {
	padding-right: 8px;
}

.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}

.ps-96px {
	padding-left: 96px;
}

.pe-20 {
	padding-right: 20%;
}

.px-96px {
	padding-left: 96px;
	padding-right: 96px;
}

.p-8px {
	padding: 8px;
}

.p-16px {
	padding: 16px;
}

.py-16px {
	padding-top: 16px;
	padding-bottom: 16px;
}

.p-24px {
	padding: 24px;
}

.p-32px {
	padding: 32px;
}

.ps-32px {
	padding-left: 32px;
}

.pt-16px {
	padding-top: 16px;
}

.px-24px {
	padding-left: 24px;
	padding-right: 24px;
}

.p-64px {
	padding: 64px;
}

